/* Sobre.css */

.sobre-page {
    background-color: white; /* Define o fundo branco para a página */
    padding: 5px;
    margin-left: 1px !important; /* Espaçamento esquerdo */
    margin-right: 1px !important; /* Espaçamento direito */
  }
  
  .sobre-page:empty {
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    font-size: 4rem !important; /* Tamanho do título grande */
    margin-bottom: 10px; /* Espaçamento inferior */
    text-align: left !important;
    color: #000000;
    font-weight: bold;
    font-family: 'Noto Sans', sans-serif !important;
  }
  
  p {
    font-size: 2rem; /* Tamanho do subtítulo */
    text-align: left;
    color: #000000;
    font-family: 'Noto Sans', sans-serif;
  }
  
  img {
    max-width: 100%; /* Garante que as imagens não excedam a largura do contêiner */
    height: auto; /* Mantém a proporção da imagem */
    margin: 10px 0; /* Adiciona um espaçamento entre as imagens */
  }
  
  .texto-caixa {
    background-color: #f0f0f0; /* Cor de fundo para as caixas de texto */
    padding: 10px; /* Adiciona espaçamento interno às caixas de texto */
    margin: 10px 0; /* Espaçamento entre as caixas de texto */
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.2rem !important ;
    justify-content: center;
  }
  
  .titulo-caixa {
    font-weight: bold; /* Define a fonte em negrito */
    font-size: 1.2rem; /* Define o tamanho da fonte */
    color: #000000; /* Define a cor do texto (por exemplo, preto) */
    display: flex;
    justify-content: center !important;
  }

  .texto-caixa p {
    font-size: 1rem;
  }