/* Custom CSS to change btn-primary to black */
.btn-custom {
    color: white;
    background-color: black;
    border-color: black;
  }
  .btn-custom:hover {
    color: white;
    background-color: grey;
    border-color: black;
  }
  .btn-custom:disabled {
    background-color: #3e3838;
    border-color: black;
    color: white;
  }
  
  .custom-spinner {
    border-color: black; /* Set border color to black */
  }
  
  /* Ensure spinning effect */
  .custom-spinner::after {
    border-color: black;
    border-right-color: transparent;
  }

  /* Set border color to black for the grow animation */
.custom-spinner-grow .spinner-grow {
  border-color: black;
}

/* Ensure spinning effect */
.custom-spinner-grow .spinner-grow::before {
  border-color: black;
  border-right-color: transparent;
}
.round-label {
  border-radius: 999px; /* Use a large value for a perfect circle */
  padding: 6px 10px; /* Adjust padding as needed */
  color: black !important; /* Change color to black */
  background-color: white !important; /* Set background color to white or any other color you prefer */
  border: 1px solid black !important; /* Add border */
}
.custom-modal-width{
  max-width: 1000px;
}
