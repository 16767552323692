.navbar-light .navbar-nav .nav-link {
  color: #000000;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000000;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-light .navbar-brand {
  color: #000000;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-light .navbar-toggler {
  color: #000000;
  border-color: #fff;
  font-family: 'Noto Sans', sans-serif;
}

.navbarButton{
  font-family: 'Noto Sans', sans-serif;
  color:#000000 !important;
  font-size: 1em;
  text-align: center;
}

.navbarButton-primary {
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #000000 !important;
  --bs-btn-font-family: 'Noto Sans', sans-serif;
  --bs-btn-active-color: #000000;
  --bs-nav-link-active-font-weight: bold;
}

.navbarButton:hover { /* Botões: Início, Sobre, Preços, Contato */
  color: #000000 !important;
  font-weight: bold;
  background-color: #fff !important;
}

.profileButton {
  color: #000000;
  font-family: 'Noto Sans', sans-serif;
  margin: 0px !important;
  font-size: 1em;
}

.profileButton.active {
  font-weight: bold;
  background-color: #fff !important;
  color: #000000 !important;
}

.custom-button { /* Botões: Entrar, cadastre-se */
  background-color: #fff !important;
  color: #000000 !important;
  border-color: #000000 !important;
  margin-right: 10px !important;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1em;
}

.custom-button:hover {
  background-color: #000000 !important;
  color: #fff !important;
  border-color: #000000 !important;
  margin-right: 10px !important;
  font-family: 'Noto Sans', sans-serif;
}
.custom-button:active {
  background-color: #000000 !important;
  color: #fff !important;
}
.color-nav {
  background-color: transparent !important;
}

.custom-button.active {
  font-weight: bold;
}
.navbarButton, .active {
  font-weight:900;
  text-decoration:underline ;
}

.navbarButton.selected{
  font-weight:900;
  text-decoration:underline ;
}

.dropdown-menu.show {
  width: fit-content;
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.dropdown-item.active {
    --bs-dropdown-link-active-bg: #000000;
    --bs-dropdown-link-active-color: #ffffff ;/* Your custom background color here */;
}

@media screen and (max-width:758px) {
  .custom-button{
    float:inline-start;
  }
}

.navbarButton.selected {
  background-color: #000000;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

/* Estilos para telas móveis */

@media (max-width: 767px) {
  .navbar-nav {
    width: 100%;
  }
  
  .nav-item {
    width: 50%;
    text-align: center;
  }
  
  .home-nav .navbar-brand {
    order: 1; /* altera a ordem do logo em telas móveis */
  }
  
  .nav-item:nth-child(1) {
    order: 2; /* altera a ordem do primeiro botão em telas móveis */
  }
  
  .nav-item:nth-child(2) {
    order: 3; /* altera a ordem do segundo botão em telas móveis */
  }
  
  .navbar-toggler {
    order: 4; /* altera a ordem do botão hamburguer em telas móveis */
  }
  
  .navbar-collapse {
    order: 5; /* altera a ordem do menu deslizante em telas móveis */
  }

  .navbarButton {
    font-size: 0.800em !important;
    }

  .profileButton{
    font-size: 0.650em;
  }

  .profileButton.dropdown-item{
    width: 50px !important;
  }

  .dropdown-menu.show.dropdown-menu-end{
    width: fit-content !important;
  }
}
.close-icon {
  font-size: 24px; /* Tamanho do ícone */
  color: #333; /* Cor do ícone */
}
.btn-custom {
  color: white;
  background-color: black;
  border-color: black;
}
.btn-custom:hover {
  color: white;
  background-color: grey;
  border-color: black;
}
.dropdown-toggle::after {
  border-top-color: white !important;
}
.navbar-nav .nav-link {
  color: black !important;
}

#dropdown-basic {
  background-color: black;
  border-color: black;
}
#dropdown-basic:hover {
  background-color: grey;
  border-color: black;
  color: white;
}
.profileButton:active {
  background-color: black;
  color: white; /* Optionally, change text color to white */
  /* Add any other styles you want for the clicked state */
}
